<template>
  <table class="table">
    <thead>
    <tr>
      <th class="border-top-0 p-0" v-if="entityName === 'order' && $store.getters.userIsAdmin">
        <input
            class="ml-2 mb-3"
            type="checkbox"
            :id="'checkbox-list-order-all'"
            :value="'all'"
            @change="checkboxListOrder($event, 'all')"
        />
      </th>
      <th v-for="(th, index) in headerList" :key="index" class="border-top-0 p-2">
        <div class="d-flex" :class="(th['align'] === 'center') ? 'justify-content-center' : 'justify-content-start'" v-if="th['filter']">
          <div class="mt-2 ml-2">
            {{ (th['name']) ? th['name'] : $t(th['indexTrad']) }}
          </div>

          <b-icon
              v-if="th['order'] === 'ASC'"
              v-on:click="filterOrderBy(th['index'], th['order'], index)"
              icon="caret-down-fill"
              font-scale="1.2"
              variant="info"
              class="ml-4"
          ></b-icon>
          <b-icon
              v-else-if="th['order'] === 'DESC'"
              v-on:click="filterOrderBy(th['index'], th['order'], index)"
              icon="caret-up-fill"
              font-scale="1.2"
              variant="success"
              class="ml-4"
          ></b-icon>
          <div class="d-flex flex-column h-30-px" v-else>
            <b-icon
                v-on:click="filterOrderBy(th['index'], th['order'], index)"
                icon="caret-up-fill"
                font-scale="1"
                variant="secondary"
                class="ml-4"
            ></b-icon>
            <b-icon
                v-on:click="filterOrderBy(th['index'], th['order'], index)"
                icon="caret-down-fill"
                font-scale="1"
                variant="secondary"
                class="ml-4"
            ></b-icon>
          </div>
        </div>
        <div class="text-center mb-1" v-else-if="(th['isOnlyReseller']) ? $store.getters.userIsReseller : true">
          {{ (th['name']) ? th['name'] : $t(th['indexTrad']) }}
        </div>
      </th>
      <th v-if="isAction" class="border-top-0 border-right-0 my-auto">
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(data, index) in datas" :key="data.key">
      <!-- ORDER CHECKBOX -->
      <td v-if="entityName === 'order' && $store.getters.userIsAdmin">
        <input
            class="form-check-input ml-0"
            type="checkbox"
            :id="'checkbox-list-order-' + index"
            :value="data.id"
            @change="checkboxListOrder($event, data.id)"
        />
      </td>
      <td
          :data-label="headerList[index]['name']"
          class="app-color-text" :class="(th['index'] === 'name') ? 'small-cell' : ''"
          :style="{textAlign: th['align']}"
          v-for="(th, index) in headerList"
          :key="index"
      >
        <span class="date" v-if="th['date']">
          {{ data[th['index']] | moment((th['datetime']) ? 'DD/MM/Y kk:mm:ss' : 'DD/MM/Y') }}
        </span>
        <span v-else-if="th['index'] === 'responseReseller'">
          {{ (data[th['index']] === 'print-anyway') ? 'Imprimer en l\'état' : 'Renvoyez un nouveau fichier' }}
        </span>
        <!-- ROLES USER -->
        <span v-else-if="th['index'] === 'roles'" >
          <b-icon v-if="data.roles.includes('ROLE_RESELLER')" stacked icon="briefcase" variant="info" scale="0.6"></b-icon>
          <b-icon v-else-if="data.roles.includes('ROLE_ADMIN')" stacked icon="person-bounding-box" variant="danger" scale="0.6"></b-icon>
          <b-icon v-else-if="data.roles.includes('ROLE_READER')" stacked icon="book" variant="success" scale="0.6"></b-icon>
          <b-icon v-else-if="data.roles.includes('ROLE_RESELLER_FRONT')" stacked icon="person" variant="success" scale="0.6"></b-icon>
        </span>
        <!-- TRANSLATION -->
        <span v-else-if="th['index'] === 'name' && th['isTranslatable']">
          {{ getTranslation(data) }}
        </span>
        <!-- STATUS -->
        <span v-else-if="th['index'] === 'status' && !th['switch']">
          <template v-if="entityName === 'orderRequestCancel'">
            <template v-if="data[th['index']] === 'approved'">
              <b-icon icon="check-circle" variant="success" scale="1.5" :id="'tooltip-target-approved-' + data.id"></b-icon>
              <b-tooltip :target="'tooltip-target-approved-' + data.id" triggers="hover">
                {{ $t('common.approved') }}
              </b-tooltip>
            </template>
            <template v-else-if="data[th['index']] === 'unapproved'">
              <b-icon icon="x-circle" variant="danger" scale="1.5" :id="'tooltip-target-unapproved-' + data.id"></b-icon>
              <b-tooltip :target="'tooltip-target-unapproved-' + data.id" triggers="hover">
                {{ $t('common.unapproved') }}
              </b-tooltip>
            </template>
            <template v-else-if="data[th['index']] === 'pending'">
              <b-icon icon="question-circle" variant="warning" scale="1.5" :id="'tooltip-target-pending-' + data.id"></b-icon>
              <b-tooltip :target="'tooltip-target-pending-' + data.id" triggers="hover">
                {{ $t('common.waiting') }}
              </b-tooltip>
            </template>
          </template>
          <template v-else-if="entityName === 'order'">
            <div
                show
                class="badge p-2"
                :class="[
                    handleColorStatusOrder(data[th['index']].ext_id),
                    'w-100'
                  ]
                "
            >
              {{ data[th['index']].name }}
            </div>
          </template>
          <template v-else>
            {{ data[th['index']] }}
          </template>
        </span>
        <!-- SWITCH -->
        <span v-else-if="th['switch']">
          <template v-if="entityName === 'user'">
            <b-form-checkbox
              v-if="$store.getters.getCurrentUser.id !== data['id']"
              v-model="data[th['index']]"
              name="check-button"
              switch v-on:change="eventChangeSwitch(data[th['index']], th['index'], data['id'])">
            </b-form-checkbox>
          </template>
          <template v-else>
            <b-form-checkbox
                v-model="data[th['index']]"
                name="check-button"
                switch v-on:change="eventChangeSwitch(data[th['index']], th['index'], data['id'])">
            </b-form-checkbox>
          </template>
        </span>
        <!-- CENTIMETER -->
        <span class="" v-else-if="th['centimeter']">
          {{ (data[th['index']] /10) }}
        </span>
        <!-- COPY -->
        <span class="text-primary c-pointer" v-else-if="th['copy']" v-on:click="copyEntry(data[th['index']])">
          {{ data[th['index']] }}
        </span>
        <template v-else-if="th['index'] === 'estimatedAt'">
          {{ calculatedDate(data) | moment('DD/MM/Y') }}
        </template>
        <!-- CANCEL ORDER -->
        <template v-else-if="th['index'] === 'cancel-order' && entityName === 'order' && $store.getters.userIsReseller">
          <template v-if="data['requestCancels'][0]">
            <div
                class="text-center p-1 w-100 small font-weight-bold"
                :class="(data['requestCancels'][0]['status'] === 'pending') ? 'text-warning' : 'text-success'"
            >
              {{
                (data['requestCancels'][0]['status'] === 'pending')
                ? $t('pages.orderCancel.requestInProgress')
                : $t('pages.orderCancel.requestCompleted')
              }}
            </div>
          </template>
          <template v-else>
            <template v-if="data['status']['ext_id'] === 100">
              <div class="alert text-center text-danger p-1 w-100 small font-weight-bold">
                {{ $t('pages.orderCancel.cancelledOrder') }}
              </div>
            </template>
            <template v-else>
              <button
                  :key="data['index']"
                  class="btn btn-sm btn-block btn-outline-info mb-2"
                  v-on:click="actionEvent({ index: 'cancelOrder', linkname: 'cancel-order' }, data['id'])"
              >
                {{ $t('pages.orderCancel.requestCancellation') }}
              </button>
            </template>
          </template>
        </template>
        <!-- RELATION -->
        <span v-else-if="th['relation']">
          <template v-if="th['index'] === 'provideNumberPerRolls'">
            <template v-for="(elem, index) in data[th['index']]">
              <b-badge class="ml-2" :key="index">{{ (elem.name === 'oui') ? $t('common.yes') : $t('common.no') }}</b-badge>
            </template>
          </template>
          <template v-else-if="th['index'] === 'pose'">
            {{ data[th['index']].name }}
          </template>
          <template v-else-if="th['index'] === 'category'">
            {{ data[th['index']].name }}
          </template>
          <template v-else-if="th['index'] === 'scenario'">
            {{ data[th['index']].name }}
          </template>
          <template v-else-if="th['index'] === 'status' && entityName==='order'">
            {{ data[th['index']].name }}
          </template>
          <template v-else-if="th['index'] === 'tracking'">
            <template v-if="data[th['index']].length > 0">
              <template v-for="(tracking, index) in data[th['index']]">
                <b-badge class="ml-2" variant="primary" :key="index">{{ (tracking) ? tracking.carrier : 'aucun' }}</b-badge>
              </template>
            </template>
            <template v-else><b-badge class="ml-2">{{ $t('common.none') }}</b-badge></template>
          </template>
          <template v-else-if="th['index'] === 'user'">
            <template v-if="data[th['index']].company">{{ data[th['index']].company }}</template>
            <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
          </template>
          <template v-else-if="th['index'] === 'reseller'">
            <template v-if="data[th['index']].company">{{ data[th['index']].company }}</template>
            <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
          </template>
          <template v-else-if="th['index'] === 'quote'">
            <template v-if="th['specific-relation'] === 'quote-reference'">
              <template v-if="data[th['index']].reference">{{ data[th['index']].reference }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'quote-diameter'">
              <template v-if="data[th['index']].diameter">{{ data[th['index']].diameter }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'quote-scenario'">
              <template v-if="data[th['index']].scenario">{{ data[th['index']].scenario.uid }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'quote-width'">
              <template v-if="data[th['index']].width && data[th['index']].height">
                {{ data[th['index']].width }} x {{ data[th['index']].height }} <sub>cm</sub>
              </template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'quote-height'">
              <template v-if="data[th['index']].height">{{ data[th['index']].height }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
          </template>
          <template v-else-if="th['index'] === 'order'">
            <template v-if="th['specific-relation'] === 'order-not-conformity-order'">
              <template v-if="data[th['index']].id">{{ data[th['index']].id }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'order-quote-scenario'">
              <template v-if="data[th['index']].quote.scenario">{{ data[th['index']].quote.scenario.name }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'order-id'">
              <template v-if="data[th['index']]">{{ data[th['index']].id }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'order-not-conformity-quote-reference'">
              <template v-if="data[th['index']].quote.reference">{{ data[th['index']].quote.reference }}</template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'order-status'">
              <template v-if="data[th['index']].status">
                <div
                  show
                  class="badge p-2"
                  :class="[
                    handleColorStatusOrder(data[th['index']].status.ext_id),
                    'w-100'
                  ]
                "
                >
                  {{ data[th['index']].status.name }}
                </div>
              </template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
            <template v-if="th['specific-relation'] === 'order-createdAt'">
              <template v-if="data[th['index']].createdAt">
                {{ data[th['index']].createdAt | moment('DD/MM/Y kk:mm:ss') }}
              </template>
              <template v-else><b-badge>{{ $t('common.none') }}</b-badge></template>
            </template>
          </template>
          <template v-else>
            <template v-for="(elem, index) in data[th['index']]">
              <b-badge class="ml-2" :key="index">{{ elem.number }}</b-badge>
            </template>
          </template>
        </span>
        <!-- MONEY -->
        <span v-else-if="th['money']">
          {{ data[th['index']] }} €
        </span>
        <span v-else>
          <template v-if="data[th['index']]">{{ data[th['index']] }}</template>
          <template v-else-if="th['isOnlyReseller'] ? !!($store.getters.userIsReseller) : true">
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </span>
      </td>
      <td data-label="Actions" class="border-right-0" v-if="isAction">
        <div class="d-flex justify-content-end">
          <b-dropdown class="actions-table-dropdown" variant="light" no-caret>
            <template #button-content>
              <span>...</span>
            </template>
            <!-- ACTION ORDER REQUEST CANCEL -->
            <template v-if="entityName === 'orderRequestCancel' && data.status !== 'pending'">
              <div
                class="d-flex justify-content-start c-pointer cancel-order-request-action"
                 v-on:click="actionEvent({ index: 'cancel', linkname: 'Cancel' }, data['id'])"
              >
                <b-icon
                    class="ml-2"
                    icon="x-circle"
                    font-scale="1"
                    variant="warning"
                ></b-icon>
                <span class="span-table-action ml-2">{{ $t('common.cancel') }}</span>
              </div>
            </template>
            <b-dropdown-item v-for="(action, index) in actionList" :key="index" class="w-100 p-0" v-else>
              <router-link
                  v-if="action['link-action']"
                  class="w-100 d-block"
                  :to="{
                    name: action['routeName'],
                    params: (action['params'])
                    ? {'id' : (action['is-specific-id']) ? data[action['id-index']].id : data['id'] }
                    : null,
                    query: { paramFrom: ((action['is-specific-id'])) ? action['from'] : null }
                  }"
              >
                <div class="d-flex justify-content-start">
                  <b-icon
                      v-if="action['icon']"
                      :icon="action['icon']"
                      font-scale="1"
                      :variant="action['icon-variant']"
                  ></b-icon>
                  <span class="ml-2">{{ $t(action['indexTrad']) }}</span>
                </div>
              </router-link>
              <div v-else-if="action['isSpecificAction']">
                <template v-if="action['isAdminOnly'] && $store.getters.userIsAdmin">
                  <div v-on:click="actionEvent(action, data['id'])">
                    <div class="d-flex justify-content-start">
                      <b-icon
                          v-if="action['icon']"
                          :icon="action['icon']"
                          font-scale="1"
                          :variant="action['icon-variant']"
                      ></b-icon>
                      <span class="span-table-action ml-2">{{ $t(action['indexTrad']) }}</span>
                    </div>
                  </div>
                </template>
                <template v-else-if="action['isResellerOnly'] && $store.getters.userIsReseller">
                  <div v-on:click="actionEvent(action, data['id'])" v-if="entityName === 'orderNotConformity'">
                    <div class="d-flex justify-content-start" v-if="data.order.status.ext_id === 5">
                      <b-icon
                          v-if="action['icon']"
                          :icon="action['icon']"
                          font-scale="1"
                          :variant="action['icon-variant']"
                      ></b-icon>
                      <span class="span-table-action ml-2">{{ $t(action['indexTrad']) }}</span>
                    </div>
                  </div>
                  <div v-on:click="actionEvent(action, data['id'])" v-else>
                    <div class="d-flex justify-content-start">
                      <b-icon
                          v-if="action['icon']"
                          :icon="action['icon']"
                          font-scale="1"
                          :variant="action['icon-variant']"
                      ></b-icon>
                      <span class="span-table-action ml-2">{{ $t(action['indexTrad']) }}</span>
                    </div>
                  </div>
                </template>
              </div>
              <div v-else-if="action['isSpecificUniqueAction']">
                <template v-if="action['isAdminOnly'] && $store.getters.userIsAdmin">
                  <b-dropdown-divider></b-dropdown-divider>
                  <div v-on:click="actionUniqueEvent(action, data['id'])">
                    <div class="d-flex justify-content-start">
                      <b-icon
                          v-if="action['icon']"
                          :icon="action['icon']"
                          font-scale="1"
                          :variant="action['icon-variant']"
                      ></b-icon>
                      <span class="span-table-action ml-2">{{ $t(action['indexTrad']) }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>

import moment from 'moment'

export default {
  props: {
    headerList: Array,
    isAction: Boolean,
    actionList: Array,
    specificAction: Array,
    specificUniqueAction: Object,
    selectable: Boolean,
    listRender: Array,
    datas: Array,
    entityName: String
  },
  data() {
    return {
      switchChecked: false
    }
  },
  methods: {
    calculatedDate(data) {
      let delay = this.getDelayOnCategory(data)
      return moment(data.createdAt, "YYYY-MM-DD").add(delay, 'days')
    },
    getDelayOnCategory(data) {
      let delay = null
      data.quote.scenario.categoriesScenarios.forEach((item) => {
        if(item.category.pose.shortCode === data.quote.application) {
          delay = item.delay
        }
      })

      return delay
    },
    /**
     * Emit filter for parent !
     *
     * @param thIndex
     * @param thOrder
     * @param index
     */
    filterOrderBy(thIndex, thOrder, index)
    {
      // Set order on headerList
      if(!thOrder) {
        this.headerList[index].order = 'DESC'
      } else if(thOrder === 'ASC') {
        this.headerList[index].order = 'DESC'
      } else {
        this.headerList[index].order = 'ASC'
      }

      // Reset all order from headerList
      this.headerList.forEach((value, indexForeach) => {
        if(index !== indexForeach) {
          value['order'] = ''
        }
      })

      this.$emit('ordered', { index: thIndex, order: this.headerList[index].order })
    },
    /**
     * Event on change switch
     *
     * @param index
     * @param thIndex
     * @param id
     */
    eventChangeSwitch(index, thIndex, id) {
      this.$emit('switch', { value: index, property: thIndex, entityName: this.entityName, id: id })
    },
    /**
     * Event specific action
     *
     * @param action
     * @param id
     */
    actionEvent(action, id) {
      this.$emit('specific-action', { action: action.index, entityName: this.entityName, id: id })
    },
    /**
     * Event specific UNIQUE action
     *
     * @param action
     * @param id
     */
    actionUniqueEvent(action, id) {
      this.$emit('specific-unique-action', { action: action.index, entityName: this.entityName, id: id })
    },
    /**
     * Simulate Ctrl + C
     *
     * @param token
     */
    copyEntry(token) {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = token;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      this.$bvToast.toast('Token : '+ token, {
        title: 'Token copié avec succès',
        variant: 'success',
        solid: true
      })
    },
    checkboxListOrder($event, id) {
      if (id === 'all') {
        if ($event.target.checked) {
          this.$emit('emit-checkbox-list-all', this.datas)

          // Handle checked all input
          this.handleCheckedInput(true)
        } else {
          // Handle checked all input
          this.handleCheckedInput(false)
          this.$emit('emit-checkbox-list-all', null)
        }
      } else {
        this.$emit('emit-checkbox-list', { checked: !!($event.target.checked), id: id })
      }
    },
    handleCheckedInput(isChecked) {
      for (let i = 0; i < this.datas.length; i++) {
        document.getElementById('checkbox-list-order-' + i).checked = isChecked
      }
    },
    handleColorStatusOrder(extId) {
      switch (extId) {
        case 0:
          return 'alert-warning'
        case 1:
          return 'alert-info'
        case 2:
          return 'alert-dark'
        case 3:
          return 'alert-primary'
        case 4:
          return 'alert-warning'
        case 5:
          return 'alert-not-conformity'
        case 99:
          return 'alert-success'
        case 100:
          return 'alert-danger'
      }
    },
    getTranslation(data) {
      let findTranslation = data.translations.find(obj => obj.locale === this.$store.getters.getLocale)
      if (findTranslation) {
        return findTranslation.name
      }
    }
  },
}
</script>
